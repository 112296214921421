.wallet-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 150px;
  overflow-x: hidden;
}

.wallet-content {
  flex: 1;
  width: 100%;
  min-height: calc(100vh - 60px - 200px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
}

.wallet-sidebar {
  width: 250px;
  background-color: #ffffff;
  padding: 1rem;
  height: calc(100vh - 150px);
  position: fixed;
  left: 0;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
}

.sidebar-title {
  display: none;
}

.sidebar-buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
  height: calc(100vh - 250px);
  position: relative;
}

.button-icon {
  font-size: 1.3rem;
  color: #333;
}

.sidebar-button {
  background-color: transparent;
  border: none;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.9rem;
  font-weight: normal;
  color: #333;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: left;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.sidebar-button:hover {
  background-color: #f5f5f5;
}

.sidebar-buttons::after {
  content: '';
  flex: 1;
  min-height: calc(100vh - 300px);
}

.logout-button {
  background-color: #dc3545;
  color: white;
  border: none;
  width: calc(100% - 1rem);
  height: 40px;
  border-radius: 4px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.9rem;
  font-weight: normal;
  cursor: pointer;
  margin: 0.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
}

.logout-button .button-icon {
  color: white;
}

.wallet-main-content {
  flex: 1;
  margin-left: 250px;
  padding: 2rem;
}

@media screen and (max-width: 768px) {
  .wallet-page {
    padding-top: 150px;
  }
  
  .wallet-content {
    flex-direction: column;
  }

  .wallet-sidebar {
    width: 100%;
    height: auto;
    position: relative;
    box-shadow: none;
    border-bottom: 1px solid #eee;
  }

  .wallet-main-content {
    margin-left: 0;
    padding: 1rem;
  }

  .sidebar-buttons {
    height: auto;
  }
  
  .logout-button {
    position: relative;
    margin-top: 2rem;
  }
} 

/* Estilos para el menú hamburguesa */
.wallet-hamburger-menu {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  position: fixed;
  top: 46px;
  right: 20px;
  z-index: 1000;
}

.wallet-hamburger-menu .hamburger-line {
  display: block;
  width: 25px;
  height: 2px;
  background-color: #190E5F;
  margin: 4px 0;
  transition: all 0.3s ease;
  border-radius: 2px;
}

.wallet-hamburger-menu:hover .hamburger-line {
  background-color: #65C0CF;
}

.wallet-mobile-menu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(135deg, #190E5F, #65C0CF);
  padding: 2rem;
  z-index: 999;
  transform: translateX(100%);
  transition: transform 0.3s ease;
}

.wallet-mobile-menu.active {
  transform: translateX(0);
}

.mobile-menu-close {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.mobile-menu-close span {
  display: block;
  width: 25px;
  height: 3px;
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
}

.mobile-menu-close span:first-child {
  transform: translate(-50%, -50%) rotate(45deg);
}

.mobile-menu-close span:last-child {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.mobile-menu-buttons {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-end;
  padding-right: 2rem;
}

.mobile-menu-button {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50px 0 0 50px;
  color: white;
  padding: 1rem;
  width: 75%;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  margin-left: auto;
}

.mobile-menu-button:hover {
  background: rgba(255, 255, 255, 0.2);
}

.mobile-menu-button.logout {
  background: #dc3545;
  margin-top: auto;
  border-radius: 50px 0 0 50px;
}

@media screen and (max-width: 768px) {
  .wallet-hamburger-menu {
    display: block;
  }

  .wallet-mobile-menu {
    display: block;
  }

  .wallet-sidebar {
    display: none;
  }

  .wallet-main-content {
    margin-left: 0;
    padding: 1rem;
  }
}