.container-menu {
    position: relative;
    top: 50px;
    background-color: #eceded;
    width: 20%;
    height: calc(105vh - 80px);
    border-top-left-radius: 0px;
    border-top-right-radius: 38px;
    border-bottom-left-radius: 60;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); 
  }

  .container-button{
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    left: 40%;
    top: 19%;
    gap: 30px;
}

.transactions{
  background-color: #6AB140;
  border: #6AB140;
  width: 199px;
  height: 58px;
  border-radius: 50px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: white;
}

.logout{
    background-color: #9E3939;
  border: #9E3939;
  width: 199px;
  height: 58px;
  border-radius: 50px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: white;
  }