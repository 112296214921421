.contact-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(rgba(25, 14, 95, 0.7), rgba(25, 14, 95, 0.7)),
              url('https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?q=80&w=2072&auto=format&fit=crop');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.form-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem;
  margin-top: 150px;
  width: 100%;
}

.form-overlay {
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  margin: 0 auto;
}

.form-container {
  background-color: #447CA5;
  padding: clamp(2rem, 5vw, 3rem);
  border-radius: 8px;
  width: 100%;
  max-width: 550px;
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  margin: 20px auto;
}

h2 {
  text-align: center;
  margin-bottom: 2rem;
  color: white;
  font-size: clamp(1.5rem, 4vw, 2rem);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  padding-bottom: 1rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background-color: #f4b41a;
  border-radius: 2px;
}

.form-group {
  margin-bottom: 1.5rem;
  padding: 0 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: white;
  font-size: clamp(0.9rem, 2vw, 1rem);
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}

input, textarea {
  width: 100%;
  padding: 0.8rem 1rem;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  font-size: clamp(0.875rem, 2vw, 1rem);
  background-color: white;
  transition: all 0.3s ease;
  color: #333;
}

input:focus, textarea:focus {
  border-color: #f4b41a;
  box-shadow: 0 0 0 3px rgba(244, 180, 26, 0.2);
  outline: none;
}

input::placeholder, textarea::placeholder {
  color: #999;
  font-style: italic;
}

textarea {
  min-height: clamp(80px, 20vh, 100px);
  resize: vertical;
}

.submit-button {
  width: calc(100% - 2rem);
  margin: 0 1rem;
  padding: clamp(0.8rem, 3vw, 1rem);
  background-color: #190E5F;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: clamp(0.875rem, 2vw, 1rem);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.submit-button:hover {
  background-color: #f4b41a;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(244, 180, 26, 0.3);
}

/* Media Queries */
@media (max-width: 480px) {
  .form-container {
    width: 95%;
    padding: 1rem;
  }
  
  .form-section {
    padding: 0.5rem;
  }
  
  .form-overlay {
    padding: 0.25rem;
  }
  
  .form-group {
    padding: 0 0.5rem;
  }
  
  .submit-button {
    width: calc(100% - 1rem);
    margin: 0 0.5rem;
  }
}

@media (max-height: 600px) {
  .form-overlay {
    align-items: flex-start;
    padding-top: 2rem;
  }
  
  .form-container {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

/* Ajustes para dispositivos con pantalla táctil */
@media (hover: none) {
  input, textarea, button {
    font-size: 16px; /* Previene el zoom en iOS */
  }
  
  .submit-button {
    padding: 1rem;
    margin-bottom: 1rem;
  }
}

/* Estilos para campos requeridos */
input:required, textarea:required {
  border-left: 3px solid #f4b41a;
}


.mensaje-exito {
  margin-top: 1.5rem;
  padding: 1.5rem;
  background-color: #4CAF50;
  color: white;
  border-radius: 8px;
  text-align: center;
  animation: fadeIn 0.5s ease-in;
  font-size: 1.25rem;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Agregamos media queries para diferentes tamaños de pantalla */
@media (min-width: 1024px) {
  .form-overlay {
    max-width: 800px;
  }
  
  .form-container {
    max-width: 700px;
  }
}

@media (min-width: 1440px) {
  .form-overlay {
    max-width: 1000px;
  }
  
  .form-container {
    max-width: 800px;
  }
}

/* Ajustamos los inputs y labels para pantallas grandes */
@media (min-width: 768px) {
  .form-group label {
    font-size: 1.2rem;
  }

  .form-group input,
  .form-group textarea {
    padding: 1rem;
    font-size: 1.1rem;
  }

  .submit-button {
    font-size: 1.2rem;
    padding: 1rem 2rem;
  }
}