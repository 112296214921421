@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

@font-face {
  font-family: "inter";
  src: url(../fonts/inter.ttf);
  font-weight: normal;
  font-style: bold;
}

/*Payment*/

.logo {
  top: 25px;
  position: relative;
}

.container-payment-select {
  position: relative;
  top: 50px;
  background-color: #eceded;
  width: 100%;
  height: calc(105vh - 80px);
  border-top-left-radius: 70px;
  border-top-right-radius: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-payment-text-select {
  position: relative;
  top: 50px;
  background-color: #fffafa;
  width: 90%;
  height: 476px;
  border-radius: 40px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); 
}

.text-payment-select {
  font-family: "inter";
  font-weight: 600;
  font-size: 24px;
  color: #190e5f;
  position: relative;
  width: 240px;
  display: flex;
  text-align: center;
  top: 40px;
}

.container-text-select {
  position: relative;
  text-align: start;
  justify-content: center;
  display: flex;
}

.container-text-method-payment {
  position: relative;
  font-size: 18px;
  font-family: "inter";
  color: #190e5f;
}

.container-select-method-payment {
  position: relative;
}

.payments-select {
  border: none;
  outline: none;
  background: none;
  border-radius: 5px;
  font-size: 18px;
  font-family: "inter";
  color: #190e5f;
}

.container-select-payment {
  justify-content: center;
  display: flex;
  position: relative;
  top: 90px;
}

.container-image {
  position: relative;
  top: 100px;
}

.container-counter {
  position: relative;
  top: 80px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-counter {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #FF0000;
}
