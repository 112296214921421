.footer {
  background-color: #190E5F;
  color: #ffffff;
  padding: 1.5rem;
  width: 100%;
  position: relative;
  z-index: 1000;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  align-items: center;
}

/* Brand Section */
.brand-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
}

.footer-logo {
  width: 100px;
  height: auto;
  filter: brightness(0) invert(1);
  transition: transform 0.3s ease;
}

.footer-logo:hover {
  transform: scale(1.05);
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
}

.contact-info p {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #ffffff;
  font-size: 0.85rem;
  margin: 0;
}

.contact-info i {
  color: #64C1D7;
}

/* Links Section */
.links-section {
  text-align: center;
}

.links-section h3 {
  color: #64C1D7;
  font-size: 1rem;
  margin-bottom: 0.75rem;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links li {
  margin-bottom: 0.5rem;
}

.footer-links a {
  color: #ffffff;
  text-decoration: none;
  font-size: 0.85rem;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #64C1D7;
}

.footer-links i {
  font-size: 0.75rem;
  color: #64C1D7;
}

/* Social Section */
.social-section {
  text-align: center;
}

.social-section h3 {
  color: #64C1D7;
  font-size: 1rem;
  margin-bottom: 0.75rem;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 0.75rem;
}

.social-link {
  color: #ffffff;
  font-size: 1.2rem;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
}

.social-link:hover {
  color: #64C1D7;
  background: rgba(255, 255, 255, 0.2);
}

.copyright {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .footer {
    padding: 1rem;
  }
  
  .footer-content {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    text-align: center;
    padding: 0 1rem;
  }
  
  .brand-section,
  .links-section,
  .social-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .footer-logo {
    width: 80px;
  }
  
  .contact-info {
    width: 100%;
    align-items: center;
  }

  .contact-info p {
    justify-content: center;
  }
  
  .footer-links {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .social-links {
    width: 100%;
    justify-content: center;
    gap: 1.5rem;
  }
  
  .social-link {
    width: 35px;
    height: 35px;
    font-size: 1.2rem;
  }

  .copyright {
    width: 100%;
    text-align: center;
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 480px) {
  .footer {
    padding: 1rem 0.5rem;
  }
  
  .footer-content {
    gap: 1rem;
  }

  .social-links {
    gap: 1rem;
  }
  
  .social-link {
    width: 30px;
    height: 30px;
    font-size: 1.1rem;
  }
}