@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

@font-face {
  font-family: "inter";
  src: url(../fonts/inter.ttf);
  font-weight: normal;
  font-style: bold;
}

/*Payment*/

.logo {
  top: 25px;
  position: relative;
}

.container-payment-select {
  position: relative;
  top: 50px;
  background-color: #eceded;
  width: 100%;
  height: calc(105vh - 80px);
  border-top-left-radius: 70px;
  border-top-right-radius: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-payment-text-completed {
  position: relative;
  top: 50px;
  background-color: #fffafa;
  width: 85%;
  height: 341px;
  border-radius: 40px;
}

.text-payment-completed {
  font-family: "inter";
  font-weight: 600;
  font-size: 20px;
  color: #190e5f;
  position: relative;
  width: 240px;
  display: flex;
  text-align: left;
  top: 40px;
  width: 282px;
}

.container-text-payment-completed{
    position: relative;
    text-align: start;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.container-check{
  position: relative;
  top: 25px;
}

.text-bold{
  font-family: "inter";
  font-weight: 600;
  font-size: 20px;
  color: #6AB140;
  position: relative;
  left: 5px;
}

.text-adjust{
  font-family: Arial, Helvetica, sans-serif;
  font-style: initial;
  position: relative;
  left: 5px;
  top: 1px;
  font-weight: 100;
}
.text-adjust1{
  font-family: Arial, Helvetica, sans-serif;
  font-style: initial;
  position: relative;
  right: 25px;
  top: 25px;
  font-weight: 100;
}

