.tarjetas-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;
}

.page-content {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  min-height: 100vh;
  padding-top: 150px;
}

.content {
  position: relative;
  z-index: 1;
  flex: 1 0 auto;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.footer {
  margin-top: auto;
  width: 100%;
  background-color: rgba(25, 14, 95, 0.95);
  backdrop-filter: blur(10px);
}

.background-slider {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.slick-slider {
  height: 100vh !important;
}

.slick-list, 
.slick-track, 
.slick-slide, 
.slick-slide > div {
  height: 100% !important;
}

.slide-background {
  height: 100vh;
  width: 100vw;
}

.slide-image {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.tarjetas-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  position: relative;
  z-index: 2;
  width: 100%;
}

.tarjetas-card {
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 3rem;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
}

.content h1 {
  text-align: center;
  color: #64C1D7;
  margin-top: 2rem;
  margin-bottom: 3rem;
  font-size: 4rem;
  font-weight: 900;
  text-shadow: 
    2px 2px 4px rgba(0, 0, 0, 0.3),
    0 0 20px rgba(100, 193, 215, 0.5),
    0 0 40px rgba(100, 193, 215, 0.3);
  letter-spacing: 2px;
  text-transform: uppercase;
  animation: titleGlow 2s ease-in-out infinite alternate;
}

@keyframes titleGlow {
  from {
    text-shadow: 
      2px 2px 4px rgba(0, 0, 0, 0.3),
      0 0 20px rgba(100, 193, 215, 0.5),
      0 0 40px rgba(100, 193, 215, 0.3);
  }
  to {
    text-shadow: 
      2px 2px 4px rgba(0, 0, 0, 0.3),
      0 0 30px rgba(100, 193, 215, 0.8),
      0 0 50px rgba(100, 193, 215, 0.5);
  }
}

.tarjetas-card h2 {
  color: #190E5F;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
}

.tarjetas-card > p {
  color: #333333;
  margin-bottom: 3rem;
  font-size: 1.4rem;
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;
  line-height: 1.6;
}

.tarjetas-features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 2rem;
  padding: 0 1rem;
}

.feature {
  padding: 2rem;
  border-radius: 15px;
  background: #f8f9fa;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid rgba(25, 14, 95, 0.1);
}

.feature:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(25, 14, 95, 0.1);
}

.feature h3 {
  color: #190E5F;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.feature p {
  color: #333333;
  line-height: 1.7;
  font-size: 1.25rem;
  font-weight: 500;
}

.tarjetas-cta {
  text-align: center;
  padding: 2rem;
  margin-top: 2rem;
  background: rgba(25, 14, 95, 0.1);
  border-radius: 15px;
}

.tarjetas-cta h3 {
  color: #190E5F;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.tarjetas-cta p {
  color: #333;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

.tutorial-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  background: #64C1D7;
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 25px;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
}

.tutorial-button:hover {
  transform: translateY(-2px);
  background: #4da8bf;
}

.tutorial-button .icon {
  font-size: 1.2rem;
}

@media screen and (max-width: 768px) {
  .tarjetas-section {
    padding: 0.5rem;
  }
  
  .tarjetas-card {
    padding: 1.25rem;
    margin: 0;
  }
  
  .content {
    padding: 0 0.5rem;
  }
  
  .tarjetas-features {
    grid-template-columns: 1fr;
    gap: 1rem;
    margin-top: 1.5rem;
    padding: 0;
  }
  
  .feature {
    padding: 1rem;
    margin: 0;
  }
  
  .tarjetas-card > p {
    padding: 0;
    font-size: 1.2rem;
  }
  
  .tarjetas-card h2 {
    font-size: 2rem;
    padding: 0;
  }
  
  .content h1 {
    font-size: 2.5rem;
    margin-top: 4rem;
    margin-bottom: 2rem;
    padding: 0 1rem;
  }
  
  .tarjetas-cta {
    padding: 1.5rem;
    margin: 1rem;
  }
  
  .tarjetas-cta h3 {
    font-size: 1.5rem;
  }
  
  .tarjetas-cta p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .content h1 {
    font-size: 2rem;
    margin-top: 5rem;
    margin-bottom: 1.5rem;
  }
}


