.navbar {
  background-color: #ffffff;
  padding: 0.5rem 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  height: auto;
}

@media screen and (max-width: 767px) {
  .navbar {
    height: auto;
    padding: 0.25rem 0;
  }
  
  .navbar-container {
    padding: 0.5rem;
  }
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  position: relative;
}

.navbar-logo {
  flex-shrink: 0;
  margin-right: 1rem;
}

.navbar-links {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-right: 200px;
}

.nav-link {
  background: none;
  border: none;
  color: #333333;
  font-size: 1.4rem;
  cursor: pointer;
  padding: 1rem 1.5rem;
  transition: color 0.3s;
  position: relative;
  font-weight: 600;
  display: block;
  white-space: nowrap;
}

.nav-link:hover {
  color: #000000;
}

.nav-link:hover::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #2F5186;
}
@media screen and (max-width: 767px) {
  .navbar-links .nav-link:hover::after {
    display: none;
  }
}

.contact-button {
  background-color: #65C0CF;
  color: #000000;
  border: none;
  padding: 2rem 4rem;
  border-radius: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 2rem;
  position: absolute;
  right: 2rem;
}

.contact-button:hover {
  background-color: #190E5F;
  color: #ffffff;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(101, 192, 207, 0.2);
}

@media (max-width: 767px) {
  .navbar-links {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100vh;
    width: 100%;
    background: rgba(25, 14, 95, 0.98);
    flex-direction: column;
    justify-content: center;
    transition: 0.3s ease-in-out;
    padding: 2rem;
  }

  .navbar-links.active {
    right: 0;
  }

  .navbar-logo {
    margin-right: 0;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  min-width: 320px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 1rem;
  z-index: 1000;
  margin-top: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  animation: dropdownFade 0.3s ease;
}

@keyframes dropdownFade {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}

.dropdown-content::before {
  content: '';
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 16px;
  height: 16px;
  background: white;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.dropdown-item {
  padding: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 12px;
  margin-bottom: 0.5rem;
  border: 1px solid transparent;
}

.dropdown-item:hover {
  background: rgba(98, 189, 213, 0.1);
  border: 1px solid rgba(98, 189, 213, 0.2);
  transform: translateY(-2px);
}

.dropdown-item h3 {
  color: #190E5F;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.dropdown-item p {
  color: #666;
  font-size: 0.95rem;
  line-height: 1.4;
  opacity: 0.9;
}

.dropdown-content a {
  text-decoration: none;
  display: block;
}

.dropdown-content a:last-child .dropdown-item {
  margin-bottom: 0;
}

.zion-dropdown-item {
  position: relative;
  overflow: hidden;
  color: white;
  padding: 2rem !important;
}

.zion-dropdown-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(25, 14, 95, 0.7);
  transition: background 0.3s ease;
}

.zion-dropdown-item:hover::before {
  background: rgba(25, 14, 95, 0.5);
}

.dropdown-item-content {
  position: relative;
  z-index: 1;
}

.zion-dropdown-item h3,
.zion-dropdown-item p {
  color: white;
}

/* Estilos para el botón de menú móvil */
.mobile-menu-button {
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  position: relative;
  z-index: 1100;
}

/* Botón de cierre para móvil */
.close-menu-button {
  display: none;
  background: #65C0CF;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: fixed;
  top: 1.5rem;
  right: 35px;
  z-index: 1100;
  cursor: pointer;
  transition: all 0.3s ease;
}

.close-menu-button::before,
.close-menu-button::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 3px;
  background-color: #190E5F;
  top: 50%;
  left: 50%;
  transition: background-color 0.3s ease;
}

.close-menu-button::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.close-menu-button::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.close-menu-button:hover {
  background-color: #190E5F;
}

.close-menu-button:hover::before,
.close-menu-button:hover::after {
  background-color: #65C0CF;
}

@media screen and (max-width: 767px) {
  .mobile-menu-button {
    display: block;
  }

  .navbar-links {
    position: static;
    transform: none;
    justify-content: center;
    margin-right: 0;
  }

  .contact-button {
    position: relative;
    right: auto;
    margin: 1rem auto;
  }

  .dropdown-content {
    position: static;
    width: 100%;
    box-shadow: none;
    margin-top: 1rem;
  }

  .contact-button {
    padding: 1.5rem 3rem;
    font-size: 1.8rem;
  }

  .close-menu-button {
    display: block;
  }

  .navbar-links.active {
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    background: linear-gradient(135deg, #190E5F, #65C0CF);
    padding: 1rem;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    max-height: 80vh;
    overflow-y: auto;
    z-index: 1000;
  }

  .navbar-links {
    display: none;
    margin: 0;
    width: 100%;
  }

  .nav-link {
    width: 100%;
    text-align: left;
    padding: 0.8rem;
    margin: 0.3rem 0;
    font-size: 1rem;
    color: white;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    transition: all 0.3s ease;
  }

  .nav-link:last-child {
    border-bottom: none;
  }

  .close-menu-button {
    position: fixed;
    top: 1.5rem;
    right: 35px;
  }

  .hamburger-menu {
    display: none;
  }

  .navbar-container {
    padding: 0.5rem 1rem;
  }

  .navbar-logo img {
    width: 140px;
  }

  .hamburger-menu {
    padding: 6px;
  }

  .hamburger-line {
    width: 25px;
    height: 2px;
  }
}

@media screen and (max-width: 480px) {
  .navbar-links.active {
    top: 60px;
    padding: 0.8rem;
  }

  .nav-link {
    padding: 0.6rem;
    font-size: 0.9rem;
  }
}

.dropdown button {
  font-weight: 600;
}

.hero-container {
  position: relative;
  z-index: 2;
  background: transparent;
  padding: 2rem;
  max-width: 1000px;
  margin: 0 auto;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.hero-title {
  font-size: 4.5rem;
  font-weight: 900;
  color: #64C1D7;
  text-align: center;
  line-height: 1.4;
  margin-bottom: 1rem;
  letter-spacing: -0.5px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.hero-title span {
  background: rgba(0, 0, 0, 0.5);
  color: #FFFFFF;
  padding: 0 15px;
  border-radius: 8px;
  display: inline-block;
  margin: 5px;
}

.hero-subtitle {
  font-size: 1.8rem;
  color: #64C1D7;
  text-align: center;
  font-weight: 600;
  margin-top: 1.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.hero-section {
  position: relative;
  z-index: 1;
  padding: 2rem;
  text-align: center;
}

.hero-title {
  font-size: 4.5rem;
  font-weight: 900;
  color: #64C1D7;
  text-align: center;
  line-height: 1.4;
  margin-bottom: 1rem;
  letter-spacing: -0.5px;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.8);
}

.hero-subtitle {
  font-size: 1.8rem;
  color: #64C1D7;
  text-align: center;
  font-weight: 600;
  margin-top: 1.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
}

/* Asegurarnos que todo el texto dentro del hero sea del color correcto */
.hero-section * {
  color: #64C1D7;
}

.content h1 {
  color: #64C1D7;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  font-weight: 900;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.quienes-somos-container {
  min-height: 100vh;
  padding-top: 150px;
  background-color: #f5f5f5;
}

.wallet-container {
  min-height: 100vh;
  padding-top: 150px;
  background-color: #f5f5f5;
}

.remesas-container {
  min-height: 100vh;
  padding-top: 200px;
  background: linear-gradient(rgba(25, 14, 95, 0.7), rgba(25, 14, 95, 0.7)), url('https://images.unsplash.com/photo-1580519542036-c47de6196ba5?q=80');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
}

.home-container {
  min-height: 100vh;
  padding-top: 150px;
  background-color: #f5f5f5;
}

.home-footer {
  background-color: #190E5F;
  color: white;
  text-align: center;
  padding: 2rem;
  margin-top: auto;
  width: 100%;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.footer-logo {
  width: 200px;
  height: auto;
  filter: brightness(0) invert(1);
  margin-bottom: 1rem;
}

.content {
  padding-top: 2rem;
  margin-top: 1rem;
}

.hero-section {
  padding-top: 120px;
  min-height: calc(100vh - 80px);
}

.hamburger-menu {
  display: none;
}

.show-hamburger .hamburger-menu {
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    gap: 4px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    position: fixed;
    top: 50px;
    right: 15px;
    z-index: 1000;
  }
}

.hamburger-line {
  width: 25px;
  height: 2px;
  background-color: #190E5F;
  margin: 0;
  transition: all 0.3s ease;
  border-radius: 2px;
}

.hamburger-menu:hover .hamburger-line {
  background-color: #65C0CF;
}

@media screen and (max-width: 767px) {
  .navbar-links {
    display: none;
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    background: linear-gradient(135deg, #190E5F, #65C0CF);
    padding: 1rem;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
  }

  .navbar-links.active {
    display: flex;
  }
  
  .contact-button {
    position: static;
    margin: 1rem auto;
    padding: 1rem 2rem;
    font-size: 1.6rem;
  }
}

/* Animación al abrir el menú */
.hamburger-menu.active .hamburger-line:nth-child(1) {
  transform: rotate(45deg) translate(6px, 6px);
}

.hamburger-menu.active .hamburger-line:nth-child(2) {
  opacity: 0;
}

.hamburger-menu.active .hamburger-line:nth-child(3) {
  transform: rotate(-45deg) translate(6px, -6px);
}

/* Animación para los elementos del menú */
.navbar-links.active .nav-link {
  animation: slideIn 0.3s ease forwards;
  opacity: 0;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Delays de animación para cada elemento */
.navbar-links.active .nav-link:nth-child(1) { animation-delay: 0.1s; }
.navbar-links.active .nav-link:nth-child(2) { animation-delay: 0.2s; }
.navbar-links.active .nav-link:nth-child(3) { animation-delay: 0.3s; }
.navbar-links.active .nav-link:nth-child(4) { animation-delay: 0.4s; }
.navbar-links.active .nav-link:nth-child(5) { animation-delay: 0.5s; }
.navbar-links.active .nav-link:nth-child(6) { animation-delay: 0.6s; }

/* Ajustes para tablets y dispositivos medianos */
@media screen and (max-width: 1024px) {
  .nav-link {
    padding: 0.8rem 0.6rem;
    font-size: 1rem;
  }

  .navbar-container {
    padding: 0 1rem;
    max-width: 100%;
  }

  .navbar-links {
    gap: 0.5rem;
  }

  .navbar-logo {
    margin-right: 1rem;
  }

  .navbar-logo img {
    width: 140px;
  }
}

/* Ajuste específico para pantallas entre 768px y 900px */
@media screen and (min-width: 768px) and (max-width: 900px) {
  .nav-link {
    padding: 0.8rem 0.4rem;
    font-size: 0.9rem;
  }

  .navbar-logo img {
    width: 120px;
  }
}

/* Solo activamos el menú hamburguesa en móviles */
@media screen and (max-width: 767px) {
  .hamburger-menu {
    display: none;
  }

  .show-hamburger .hamburger-menu {
    display: flex;
    flex-direction: column;
    gap: 4px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    position: fixed;
    top: 50px;
    right: 20px;
    z-index: 1000;
  }

  .navbar-links {
    display: none;
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    background: linear-gradient(135deg, #190E5F, #65C0CF);
    padding: 1rem;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
  }

  .navbar-links.active {
    display: flex;
  }

  .nav-link {
    width: 90%;
    text-align: center;
    color: white;
    margin: 0.5rem 0;
    font-size: 1.2rem;
    padding: 0.8rem;
  }
}

/* Ocultamos el menú hamburguesa y el botón de cierre en tablets y desktop */
@media screen and (min-width: 768px) {
  .hamburger-menu,
  .close-menu-button {
    display: none;
  }

  .navbar-links {
    position: relative;
    display: flex;
    background: none;
    flex-direction: row;
    padding: 0;
    box-shadow: none;
  }
}

/* Ajustes base para el navbar */
.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  position: relative;
}

.navbar-logo {
  flex-shrink: 0;
  margin-right: 1rem;
}

/* Ajustes específicos para dispositivos plegables */
@media screen and (min-width: 768px) and (max-width: 900px) {
  .navbar-container {
    padding: 0 0.5rem;
  }

  .navbar-logo {
    margin-right: 0.5rem;
  }

  .navbar-links {
    max-width: calc(100% - 130px);
    justify-content: flex-end;
    gap: 0.3rem;
    margin-right: 0;
  }

  .nav-link {
    padding: 0.6rem 0.3rem;
    font-size: 0.85rem;
    margin: 0 0.1rem;
  }

  /* Ajuste específico para el botón de contacto en tablets */
  .nav-link:last-child {
    position: relative;
    right: 0;
    padding: 0.6rem 0.8rem;
  }
}

/* Ajustes para modo tablet landscape y dispositivos plegables */
@media screen and (max-width: 1024px) and (orientation: landscape),
       screen and (min-width: 768px) and (max-height: 900px) {
  .navbar-container {
    padding: 0 0.75rem;
  }

  .navbar-logo {
    margin-right: 0.75rem;
  }

  .navbar-links {
    padding-right: 0.5rem;
  }
}

/* Ajustes específicos para dispositivos plegables y landscape */
@media screen and (max-width: 1024px) and (orientation: landscape),
       screen and (min-width: 768px) and (max-height: 900px) {
  .nav-link {
    padding: 0.6rem 0.4rem;
    font-size: 0.9rem;
  }

  .navbar-container {
    padding: 0 1.5rem;
  }

  .navbar-links {
    padding-right: 1rem;
  }

  .navbar-logo img {
    width: 120px;
  }
}

/* Ajuste específico para dispositivos plegables */
@media screen and (min-width: 768px) and (max-width: 900px) {
  .navbar-links {
    max-width: calc(100% - 140px);
    justify-content: flex-end;
  }

  .nav-link {
    padding: 0.6rem 0.3rem;
    font-size: 0.85rem;
    margin: 0 0.1rem;
  }

  .nav-link:last-child {
    margin-right: 0.5rem;
  }
}

/* Ajustes para modo tablet landscape */
@media screen and (min-width: 901px) and (max-width: 1024px) and (orientation: landscape) {
  .navbar-links {
    max-width: calc(100% - 160px);
  }

  .nav-link {
    padding: 0.7rem 0.5rem;
    font-size: 0.95rem;
  }
}

/* Estilos para el contenido del menú hamburguesa */
@media screen and (max-width: 767px) {
  .navbar-links {
    display: none;
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    background: linear-gradient(135deg, #190E5F, #65C0CF);
    padding: 1rem;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
  }

  .navbar-links.active {
    display: flex;
  }

  .nav-link {
    width: 65%;
    left: 10%;
    text-align: left;
    color: white;
    margin: 0.5rem 0;
    font-size: 1.2rem;
    padding: 0.8rem;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    transition: all 0.3s ease;
  }
}

/* Animaciones del contenido */
.navbar-links.active .nav-link {
  animation: slideIn 0.3s ease forwards;
  opacity: 0;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Delays de animación */
.navbar-links.active .nav-link:nth-child(1) { animation-delay: 0.1s; }
.navbar-links.active .nav-link:nth-child(2) { animation-delay: 0.2s; }
.navbar-links.active .nav-link:nth-child(3) { animation-delay: 0.3s; }
.navbar-links.active .nav-link:nth-child(4) { animation-delay: 0.4s; }
.navbar-links.active .nav-link:nth-child(5) { animation-delay: 0.5s; }
.navbar-links.active .nav-link:nth-child(6) { animation-delay: 0.6s; }