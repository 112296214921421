:root {
  --foreground-rgb: 1, 19, 227;
  --background-start-rgb: 1, 19, 227;
  --background-end-rgb: 1, 19, 227;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 1, 19, 227;
    --background-start-rgb: 1, 19, 227;
    --background-end-rgb: 1, 19, 227;
  }
}

body {
  margin: 0; 
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(to bottom, transparent, rgb(0, 0, 0)) rgb(255, 255, 255);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
