 @layer utilities {
    .text-balance {
      text-wrap: balance;
    }
  }

  @font-face {
  font-family:'inter' ;
  src: url(../fonts/inter.ttf);
  font-weight: normal; 
    font-style: bold; 
}

  /*login*/
  
  .logo1{
    top: 35px;
    position: relative;
  }
  
  
  .container-login {
    position: relative;
    top: 80px;
    background-color: #ECEDED;
    width: 100%;
    height: calc(100vh - 80px);
    border-top-left-radius: 70px;
    border-top-right-radius: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); 
}


  
  .title-container{
    top: -60px;
    position: relative;
    justify-content: center;
    display: flex;
    height: 200px;
    text-align: center;
    align-items: center;
  }
  
  .text-sesion{
    font-family: 'inter';
    font-weight: 600;
    font-size: 27px;
  }
  
  .container-login-camps{
    position: relative;
    top: -90px; 
    background-color: #FFFAFA;
    width: 329px;
    height: 291px; 
    border-radius: 50px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); 
  }
  
  .camps {
    position: relative;
    top: 35px;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 25px;
  }
  
  .input-login{
    border: solid 3px #BF9500;
    border-radius: 30px;
    width: 275px;
    height: 44px;
    padding-left: 20px;
    outline: none;
    color: #BF9500;
  }
  
  .input-login::placeholder{
    color: #BF9500;
    font-weight: 600;
  }
  
  .button-login{
    position: relative;
    top: 30px;
    background-color: #0019F2;
    color: white;
    font-family: 'inter';
    width: 275px;
    height: 44px;
    border-radius: 15px;
    border: solid #0019F2;
  }