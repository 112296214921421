.remesas-container {
  min-height: 100vh;
  padding-top: 250px;
  background: linear-gradient(rgba(25, 14, 95, 0.7), rgba(25, 14, 95, 0.7)), url('https://images.unsplash.com/photo-1580519542036-c47de6196ba5?q=80');
  background-size: cover;
  background-position: center;
  background-attachment: scroll;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin: 0;
}

.content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  flex: 1 0 auto;
}

.content h1 {
  color: #64C1D7;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  font-weight: 900;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  word-wrap: break-word;
  max-width: 100%;
  padding: 0 1rem;
}

@media screen and (max-width: 768px) {
  .remesas-container {
    padding-top: 180px;
    width: 100vw;
    overflow-x: hidden;
    height: auto;
  }
  
  .content {
    padding: 1rem;
    width: 100%;
    height: auto;
    overflow-y: visible;
    margin-bottom: 2rem;
  }
  
  .content h1 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    padding: 0 0.5rem;
  }
}

.remesas-card {
  background: white;
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.remesas-features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
}

.feature {
  background: rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  border-radius: 10px;
  transition: transform 0.3s ease;
  backdrop-filter: blur(10px);
}

.feature:hover {
  transform: translateY(-5px);
}

.feature h3 {
  color: #190E5F;
  margin-bottom: 1rem;
}

.remesas-steps {
  margin-top: 3rem;
}

.steps-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  margin-top: 2rem;
}

.step {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.step-number {
  width: 40px;
  height: 40px;
  background: #190E5F;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.remesas-section {
  margin-top: 2rem;
}

.remesas-benefits {
  margin-top: 4rem;
  padding-top: 2rem;
}

.remesas-benefits h3 {
  color: #190E5F;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  font-weight: 900;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.benefits-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
}

.benefit-card {
  background: rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  border-radius: 10px;
  transition: transform 0.3s ease;
  backdrop-filter: blur(10px);
}

.benefit-card:hover {
  transform: translateY(-5px);
}

.benefit-card h4 {
  color: #190E5F;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.benefit-card p {
  color: #333333;
  line-height: 1.7;
  font-size: 1.25rem;
  font-weight: 500;
}
