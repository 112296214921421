.guias-container {
  min-height: 100vh;
  padding-top: 120px;
  background: linear-gradient(rgba(25, 14, 95, 0.7), rgba(25, 14, 95, 0.7)), url('https://images.unsplash.com/photo-1516321318423-f06f85e504b3?q=80');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  color: white;
  flex: 1;
}

.content h1 {
  text-align: center;
  font-size: 3rem;
  margin-bottom: 2rem;
  color: #64C1D7;
}

.categoria-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 2rem;
  margin-bottom: 2rem;
}

.categoria-card h2 {
  color: #64C1D7;
  margin-bottom: 1.5rem;
  font-size: 2rem;
}

.videos-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  max-width: 900px;
  margin: 0 auto;
}

.video-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.video-card:hover {
  transform: translateY(-5px);
}

.thumbnail-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  max-height: 200px;
}

.video-thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.play-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.play-overlay i {
  font-size: 3rem;
  color: white;
}

.video-card:hover .play-overlay {
  opacity: 1;
}

.video-info {
  padding: 1.2rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.video-info h3 {
  font-size: 1.1rem;
  line-height: 1.4;
  margin-bottom: 0.8rem;
}

.video-info p {
  font-size: 0.85rem;
  line-height: 1.5;
  opacity: 0.9;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (max-width: 768px) {
  .content {
    padding: 1rem;
  }
  
  .videos-grid {
    grid-template-columns: 1fr;
    max-width: 500px;
  }
  
  .thumbnail-container {
    max-height: 180px;
  }
}

.video-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.video-modal-content {
  position: relative;
  width: 90%;
  max-width: 900px;
  background: #000;
  border-radius: 10px;
  padding: 20px;
  z-index: 10000;
}

.close-modal-button {
  position: fixed;
  top: 20px;
  right: 20px;
  background: #64C1D7;
  border: none;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  z-index: 10001;
}

.close-modal-button:hover {
  background: #4da8bf;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .video-modal-overlay {
    align-items: center;
    padding: 20px;
  }
  
  .video-modal-content {
    width: 95%;
    padding: 15px;
    margin: 0;
    max-height: 80vh;
  }

  .close-modal-button {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 44px;
    height: 44px;
    font-size: 28px;
    background: #190E5F;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  }

  .close-modal-button:hover {
    background: #64C1D7;
  }
} 