.quienes-somos-container {
  min-height: 100vh;
  padding-top: 200px;
  background: linear-gradient(rgba(25, 14, 95, 0.7), rgba(25, 14, 95, 0.7)), url('https://images.unsplash.com/photo-1600880292203-757bb62b4baf?q=80');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.info-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.info-section > p {
  font-size: 1.8rem;
  line-height: 1.8;
  color: #64C1D7;
  text-align: center;
  margin-bottom: 3rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-weight: 600;
  max-width: 900px;
  margin: 0 auto 3rem;
  padding: 0 1rem;
}

.card-container {
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

.card-container:hover {
  transform: translateY(-5px);
}

.value-card {
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  padding: 2.5rem;
  border-radius: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
  min-height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.value-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.value-card h3::after {
  content: '';
  display: block;
  width: 60px;
  height: 3px;
  background-color: #64C1D7;
  margin: 1rem auto;
  border-radius: 2px;
}

.value-card h3 {
  color: #190E5F;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  text-shadow: none;
}

.value-card p {
  color: #1a1a1a;
  line-height: 1.7;
  font-size: 1.25rem;
  text-align: center;
  padding: 0 1rem;
  margin-bottom: 1rem;
  font-weight: 500;
}

.values-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  margin: 3rem auto;
  max-width: 1200px;
  padding: 0 1rem;
}

.card-icon {
  font-size: 3.5rem;
  color: #64C1D7;
  margin-bottom: 2rem;
  transition: transform 0.3s ease;
}

.value-card:hover .card-icon {
  transform: scale(1.1);
}

.main-title {
  color: #64C1D7;
  text-align: center;
  font-size: 3.5rem;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 0;
  margin-bottom: 2rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  position: relative;
}

.main-title::after {
  content: '';
  display: block;
  width: 120px;
  height: 4px;
  background-color: #64C1D7;
  margin: 1rem auto;
  border-radius: 2px;
}

@media screen and (max-width: 768px) {
  .quienes-somos-container {
    padding-top: 120px;
    background-attachment: scroll;
  }
  
  .values-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
    margin: 1rem auto;
    padding: 0.5rem;
  }
  
  .value-card {
    padding: 1.5rem;
    margin: 0 0.5rem;
    min-height: auto;
  }
  
  .main-title {
    font-size: 2rem;
    margin: 1rem 0;
    padding: 0 1rem;
  }
  
  .value-card h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .value-card p {
    font-size: 1rem;
    line-height: 1.5;
    padding: 0;
  }
  
  .card-icon {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .info-section {
    padding: 1rem;
  }
  
  .info-section > p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    padding: 0 0.5rem;
  }
}

@media screen and (max-width: 480px) {
  .quienes-somos-container {
    padding-top: 100px;
  }
  
  .value-card {
    margin: 0 0.25rem;
    padding: 1.25rem;
  }
  
  .main-title {
    font-size: 1.8rem;
  }
  
  .value-card h3 {
    font-size: 1.3rem;
  }
  
  .value-card p {
    font-size: 0.9rem;
  }
}

.value-card h3, 
.value-card p,
.card-icon {
  will-change: transform;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}

.quienes-somos-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
  padding-bottom: 2rem;
}

.footer {
  margin-top: auto;
}